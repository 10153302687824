.log {
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 48px;
color:rgba(80,85,101,1);
}


// ant-layout-sider-collapsed