.myBox{
    fontSize: 12px;
     cursor: pointer;
     color: #006FFF;
     margin:auto;
   //  border-radius:30% 70% 70% 30% / 30% 30% 70% 70%;
    border-style: dashed;
     text-align: center;
     
}
.alignButton{
    margin:auto !important;
    display: block !important ;
}
.IconsWorks{
     color: gray;
     display: block;
     margin-left: -23px;
}
.ItemSet{}
